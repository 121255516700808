import * as React from "react"
import {Helmet} from "react-helmet"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\""
}

// markup
const Page404 = ({ data }) => {
  return (
    <div style={pageStyles}>
      <Helmet defer={false}>
        <title>404 Greene.Photos</title>
        <meta name="description" content="404"></meta>
      </Helmet>
      <div>404</div>
    </div>
  )
}

export default Page404
